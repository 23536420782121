<template>
  <div class="contont">
    <div class="carInfo">
      <div class="carNum">{{ tripCar.carNum }}</div>
      <el-row>
        <el-col :span="7"><span>所属公司:</span><span>{{ tripCar.companyName }}</span></el-col>
        <el-col :span="5" :offset="1"><span>所属车队:</span><span>{{ tripCar.motorcadeName }}</span>
        </el-col>
        <el-col :span="5" :offset="1"><span>所有人: </span><span>{{ tripCar.belong }}</span></el-col>
        <el-col :span="5">
          <div class="thumbnail-box"
            v-if="/^(http|https):\/\/[^\s]*.(jpg|png|jpeg|bmp)+$/.test(tripCar.driverLicenseImg.toLowerCase())">
            <div class="thumbnail">
              <el-image :src="tripCar.driverLicenseImg" fit="fill"></el-image>
              <el-button type="text" @click="showImage(tripCar.driverLicenseImg)">查看行驶证</el-button>
            </div>
            <div class="thumbnail"
              v-if="/^(http|https):\/\/[^\s]*.(jpg|png|jpeg|bmp)+$/.test(tripCar.operationCertificateImg.toLowerCase())">
              <el-image :src="tripCar.operationCertificateImg" fit="fill"></el-image>
              <el-button type="text" @click="showImage(tripCar.operationCertificateImg)">查看运营证</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7"><span> 车辆类型:</span><span>{{ tripCar.type }}</span></el-col>
        <el-col :span="5" :offset="1"><span>品牌型号:</span><span>{{ tripCar.brand }}</span>
        </el-col>
        <el-col :span="5" :offset="1"><span>车辆识别代码:</span><span>{{ tripCar.identificationCode }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <span>发动机号:</span><span>{{ tripCar.engineCode }}</span>
        </el-col>
        <el-col :span="5" :offset="1"><span>最大载客数:</span><span>{{ tripCar.maxNum }}</span>
        </el-col>
        <el-col :span="5" :offset="1"><span>注册日期: </span><span>{{ tripCar.registerTime | dateFormat }}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="17"><span>地址:</span> <span>{{ tripCar.address }}</span></el-col>
      </el-row>
    </div>
    <el-tabs class="tabs" v-model="activeName" style="width: 100%">
      <el-tab-pane label="乘车记录" name="logs" >
        <table-compnent v-if="activeName=='logs'" :records="tableData" @resetHandler="reset" @searchHandler="doSearch" :loading="logsLoading"
          :showAdd="false" :showDelete="false">
          <template v-slot:search>
            <el-form-item label="下车时间:">
              <el-date-picker v-model="TimeArr" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" :picker-options="pickerOptions" :default-time="['00:00:00', '00:00:00']"
                format="yyyy-MM-dd HH:mm">
              </el-date-picker>
            </el-form-item>
          </template>
          <template v-slot:content>
            <el-table-column prop="carNum" width="120" label="车牌号"></el-table-column>
            <el-table-column prop="driverName" label="司机姓名"></el-table-column>
            <el-table-column prop="driverMobile" width="120" label="司机电话"></el-table-column>
            <el-table-column prop="userName" label="乘客姓名"></el-table-column>
            <el-table-column prop="mobile" label="乘客联系电话" width="120"></el-table-column>
            <el-table-column label="下车时间" width="180">
              <template scope="s">
                {{ dateFormatType(s.row.endTime) }}
              </template>
            </el-table-column>
            <el-table-column prop="destination" label="下车地点"></el-table-column>
            <el-table-column prop="estimatedAmountStr" label="金额(元)" width="120"></el-table-column>
          </template>
        </table-compnent>
      </el-tab-pane>
      <el-tab-pane label="该车司机" name="drivers">
        <el-table border ref="multipleTable" :data="tripCar.drivers" tooltip-effect="dark" style="width: 100%"
          :header-cell-style="headClass" row-key="id"  v-if="activeName=='drivers'">
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="idCard" label="身份证号"></el-table-column>
          <!-- <el-table-column prop="sex" label="性别">
            <template scope="s">
              <div>
                {{ s.row.sex == 1 ? "女" : "男" }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column prop="carNumber" label="车牌号"></el-table-column>
          <el-table-column prop="companyName" label="所属公司">
            <template>
              {{ tripCar.companyName }}
            </template>
          </el-table-column>
          <el-table-column prop="nativePlace" label="籍贯"></el-table-column>
          <el-table-column prop="divisionAge" label="司龄(年)"></el-table-column>
          <el-table-column prop="carType" label="准驾车型"></el-table-column>
          <el-table-column prop="address" label="住址"></el-table-column>
          <el-table-column prop="mobile" label="联系电话"></el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="" :visible.sync="showDialog" width="50%" center>
      <img :src="ImageUrl" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
import TableCompnent from "@/components/table/TablesComponents";
import TripEventLogApi from "@/api/TripEventLogApi";
import TripCarApi from "@/api/TripCarApi";
export default {
  components: {
    TableCompnent,
  },
  data() {
    let that = this;
    return {
      tripCar: {
        id: "", //
        carNum: "", //车牌号
        type: "", //车辆类型
        belong: "", //所有人
        address: "", //地址
        brand: "", //品牌型号
        identificationCode: "", //车辆识别号
        engineCode: "", //发动机识别号
        registerTime: "", //注册时间
        companyNo: "", //所属公司
        motorcadeNo: "", //车队no
        maxNum: "", //最大载客数
        createTime: "", //创建时间
        updateTime: "", //更新时间
        operationCertificateImg: "", //营运证地址
        driverLicenseImg: "", //行驶证地址
        isDelete: "", //删除标记位
        createUser: "", //创建人
        updateUser: "", //更新人
        driverList: [],
      },
      ImageUrl: "",
      showDialog: false,
      tableData: {},
      loading: false,
      logsLoading: false,
      TimeArr: [],
      page: {
        pageSize: 20,
        pageNum: 1,
      },
      search: {
        carNum: "",
        startTime: "",
        endTime: "",
      },
      activeName: "logs",
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          that.pickerMinDate = minDate.getTime();
          if (maxDate) {
            that.pickerMinDate = "";
          }
        },
        disabledDate: (time) => {
          if (that.pickerMinDate) {
            const day30 = (30 - 1) * 24 * 3600 * 1000;
            let maxTime = that.pickerMinDate + day30;
            if (maxTime > new Date()) {
              maxTime = new Date();
              return (
                time.getTime() > maxTime ||
                time.getTime() < that.pickerMinDate - day30
              );
            } else {
              return (
                maxTime < time.getTime() ||
                time.getTime() < that.pickerMinDate - day30
              );
            }
          }

          return time.getTime() > Date.now();
        },
      },
      headClass() {
        return "background:#FAFAFA";
      },
    };
  },
  created() {
    this.getDetail();
  },
  watch: {
    $route() {
      if (this.$route.params.id != null) {
        this.getDetail();
      }
    },
  },
  filters: {
    dateFormat(e) {
      if (!e)
        return null
      let date = new Date(e);
      if (!date) return null;
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    },
  },
  methods: {
    getDetail() {
      this.loading = true;
      TripCarApi.findTripCarDetail({ id: this.$route.params.id }).then(
        (resp) => {
          console.log(resp);
          if (resp.code === '200') {
            this.tripCar = resp.data;
            this.reset();
          } else {
            this.$errorMsg(resp.msg);
          }
          this.loading = false;
        }
      );
    },
    showImage(path) {
      this.ImageUrl = path;
      this.showDialog = true;
    },
    reset() {
      this.search = this.$options.data().search;
      this.TimeArr = [];
      this.doSearch({ pageSize: 20, pageNum: 1 });
    },
    doSearch(params) {
      if (!this.tripCar.carNum) return;
      this.logsLoading = true;
      this.page = params;
      this.search.carNum = this.tripCar.carNum;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      if (this.TimeArr == null) {
        this.search.startTime = "";
        this.search.endTime = "";
      } else if (this.TimeArr && this.TimeArr.length != 0) {
        this.search.startTime = this.TimeArr[0];
        this.search.endTime = this.TimeArr[1];
      }
      TripEventLogApi.search(this.search).then((resp) => {
        if (resp.code === '200') {
          this.tableData = resp.data;
          this.logsLoading = false;
        }
      });
    },
    dateFormatType(val) {
      if (!val) {
        return "";
      }
      var date = new Date(val);
      var year = date.getFullYear();
      var month = date.getMonth() + 1; // js从0开始取
      var date1 = date.getDate();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var second = date.getSeconds();
      if ((month + "").length === 1) {
        month = "0" + month;
      }
      if ((date1 + "").length === 1) {
        date1 = "0" + date1;
      }
      if ((hour + "").length === 1) {
        hour = "0" + hour;
      }
      if ((minutes + "").length === 1) {
        minutes = "0" + minutes;
      }
      if ((second + "").length === 1) {
        second = "0" + second;
      }

      return (
        year +
        "-" +
        month +
        "-" +
        date1 +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        second
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.contont {
  width: auto;
  margin: 0 64px;
}

.carInfo {
  .carNum {
    font-size: 20px;
    font-weight: bold;
  }

  .el-row {
    height: 40px;
    line-height: 40px;
    margin: 8px 0;
  }

  .el-col span:first-of-type {
    white-space: nowrap;
  }

  .el-col span:last-of-type {
    color: #666;
    padding-left: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-all;
    white-space: nowrap;
  }
}

::v-deep .tabs .el-tabs__nav-wrap {
  width: 100%;
}

.thumbnail-box {
  width: 300px;
  display: flex;
  position: absolute;
  justify-content: baseline;
}

.thumbnail {
  display: block;
  margin: 0 10px;
  z-index: 100;
  width: 50%;
}

::v-deep .thumbnail .el-button {
  width: 100%;
  line-height: 30px;
  padding: 0;
}

::v-deep .thumbnail .el-image {
  border-radius: 4px;
  height: 100px;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
