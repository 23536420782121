import * as AppHttpKit from '@/utils/http/AppHttpKit';
import * as appHttp from '@/utils/http/apphttp'
/**
 * 以下是完成API接口调用的定义规范
 */
const TripEventLogApi = {
    search: (data) => AppHttpKit.postJSON(`/v1/tripEventLoglist`, data),//这里必须写清楚接口的注释说明
    saveTripEventLog: (data) => AppHttpKit.postJSON(`/v1/saveTripEventLog`, data),
    queryTripEventLogById: (data) => AppHttpKit.postJSON(`/v1/queryTripEventLogById/`, data),
    updateTripEventLog: (data) => AppHttpKit.postJSON(`/v1/updateTripEventLog/`, data),
    deleteTripEventLog: (data) => AppHttpKit.postJSON(`/v1/deleteTripEventLog/`, data),
    batchDeleteTripEventLog: (data) => AppHttpKit.postJSON(`/v1/batchDeleteTripEventLog/`, data),
    exportTripEventLog: (data) => appHttp.postBlob('/v1/exportTripEventLog', data)
}
export default TripEventLogApi;
